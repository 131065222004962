import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import css from './deeplaiButton.module.scss';
import LogoWhite from "../../../images/logos/deeplai-company-logo-white.svg";
import LogoBlack from "../../../images/logos/deeplai-company-logo.svg";
import {useTranslation} from "react-i18next";

const DeeplaiButton = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            companyUrl
          }
        }
      }
    `
  );

  const { t } = useTranslation();
  const {
    height = '24px',
    version = 'black'
  } = props;

  const logoAlt = 'deeplai logo logotype';
  const companyUrl = site.siteMetadata.companyUrl;

  return (
    <a href={companyUrl} target="_blank" className={css.link}>
      { version === 'white' && <img src={LogoWhite} alt={logoAlt} style={{ height }} />}
      { version === 'black' && <img src={LogoBlack} alt={logoAlt} style={{ height }} />}
    </a>
  );
};

export default DeeplaiButton;
