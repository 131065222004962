import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import css from './footer.module.scss';
import useBreakpoint from "../../hooks/useBreakpoint";
import SubpageLink from "../InternalLinks/SubpageLink";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {getRoutes} from '../routes';
import NvidiaIcon from "../../../images/logos/nvidia_inception_program_logo.svg";
import PinterestIcon from "../../../images/externalApps/pinterest_icon_w.svg";
import YouTubeIcon from "../../../images/externalApps/yt_icon_w.svg";
import FacebookIcon from "../../../images/externalApps/fb_icon_w.svg";
import LinkedInIcon from "../../../images/externalApps/in_icon_w.svg";
import InstagramIcon from "../../../images/externalApps/insta_icon_w.svg";
import TwitterIcon from "../../../images/externalApps/twitter_icon_w.svg";
import DeeplaiButton from "../DeeplaiButton/DeeplaiButton";



const Footer = props => {
  const queryResult = useStaticQuery(
    graphql`
      query {
        fluid: allFile(filter: {sourceInstanceName: {eq: "images_footer"}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 424, quality: 70) {
                ...GatsbyImageSharpFluid,
                originalName
              }
            }
          }
        }
        site {
          siteMetadata {
            socialLinks {
              facebook
              twitter
              instagram
              linkedin
              youtube
              pinterest
            }
          }
        }
      }
    `
  );


  const { site } = queryResult;

  const imagesFluid = getImagesFluid(queryResult);

  const breakpoint = useBreakpoint();

 
  const socialLinks = site.siteMetadata.socialLinks;

  

  const renderSubpageLink = (subpage, text, className = '') => {
    return (
      <SubpageLink subpage={subpage}>
        <div className={[css.navigationLink, className].join(' ')}>
          {text}
        </div>
      </SubpageLink>
    )
  };

  const renderTechnologyLink = (img, imgWidth = 'auto', url) => {
    return (
        <div className={css.technologyLogoLink}>
          <a href={url} target={'_blank'}>
            <div style={{ width: imgWidth }}>
              {img}
            </div>
          </a>
        </div>
    );
  };

  return (
    <div className={css.container} data-viewport={breakpoint.viewport}>

<div className={css.wrapper}>
        <div className={css.wrapperInner}>
          <div className={css.navigationWrapper}>
            <div className={css.navigationWrapperBg}></div>
            <div className={css.navigationWrapperHeader}>
              <div className={css.navigationWrapperBg} style={{ background: '#F6F6F6' }}></div>
              <div className={css.navigationWrapperHeaderInner}>
                We see uniqueness in everything!
              </div>
            </div>
            <div className={css.navigationWrapperInner}>
              <div className={css.column}>
              
              
                    {/* <div className={css.copyrightRow}> */}
                      {/* <div className={css.copyrightRowInner}> */}
                        <div className={css.logoAndCopyright}>
                          <div className={css.logoWrapper}>
                            <DeeplaiButton height={'20px'} version={'black'} />
                          </div>
                              <div className={css.text}>
                                &copy; Deeplai {new Date().getFullYear()}. All rights reserved.
                              </div>
                          
                          
                        </div>
                        <div className={css.media}>
                        <div className={css.socialMediaWrapper}>
                          <div className={css.socialMedia}>
                            <a href={socialLinks.facebook} title="Facebook" target="_blank" className={css.link}>
                              <img src={FacebookIcon} alt="" className={css.FacebookIcon} />
                            </a>
                            <a href={socialLinks.twitter} title="Twitter" target="_blank" className={css.link}>
                              <img src={TwitterIcon} alt="" className={css.TwitterIcon} />
                            </a>
                            <a href={socialLinks.linkedin} title="LinkedIn" target="_blank" className={css.link}>
                              <img src={LinkedInIcon} alt="" className={css.LinkedInIcon} />
                            </a>
                            <a href={socialLinks.instagram} title="Instagram" target="_blank" className={css.link}>
                              <img src={InstagramIcon} alt="" className={css.InstagramIcon} />
                            </a>
                            <a href={socialLinks.youtube} title="YouTube" target="_blank" className={css.link}>
                              <img src={YouTubeIcon} alt="" className={css.YouTubeIcon} />
                            </a>
                            <a href={socialLinks.pinterest} title="Pinterest" target="_blank" className={css.link}>
                              <img src={PinterestIcon} alt="" className={css.PinterestIcon} />
                            </a>
                          </div>
                        </div>
                        </div>
                        <div className={css.media}>
                        <div className={css.technologyLogosWrapper}>
                          <div className={css.technologyLogosWrapperRow}>
                            {renderTechnologyLink(<img src={NvidiaIcon} alt={'NVidia'} />, '80px', 'https://www.nvidia.com/en-au/deep-learning-ai/startups/')}
                            {renderTechnologyLink(renderImageByFileName(imagesFluid, 'powered-by-aws.png', 'AWS'), '50px', 'https://aws.amazon.com/')}
                          </div>
                        </div>
                      </div>              
              </div>
              <div className={css.column}>
              {renderSubpageLink([getRoutes().home], 'Home', css.subpageBoldLink)}<br/>
              {renderSubpageLink([getRoutes().UseCases], 'Use Cases', css.subpageBoldLink)}<br/>
              {renderSubpageLink([getRoutes().news], 'News', css.subpageBoldLink)}<br/>
              {renderSubpageLink([getRoutes().Contact], 'Contact', css.subpageBoldLink)}<br/>   
              </div>     
          </div>
        </div>
   
        <div className={css.trademarkRow}>
          <div className={css.trademarkRowBackground}></div>
          <div className={css.trademarkRowInner}>
              All trademarks, service marks, trade names, product names, logos and trade dress appearing on our website are the property of their respective owners. "AWS", “Amazon Web Services, “Powered by AWS” logo are trademarks of Amazon.com, Inc. or its affiliates in the United States and/or other countries. "Google", "Google Cloud", “Powered by Google Cloud” logo are registered trademarks of Google LLC. or its affiliates in the United States and/or other countries. NVIDIA, the NVIDIA logo, and all other NVIDIA product names mentioned in this website are trademarks and/or registered trademarks of NVIDIA Corporation in the U.S. and other countries. Apple, the Apple logo, MacBook Air, MacBook Pro, and iMac are trademarks of Apple Inc., registered in the U.S. and other countries. Mac App Store is a service mark of Apple Inc. Adobe®, the Adobe® logo, Adobe® Photoshop®, Adobe® Lightroom® are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries. Windows is a registered trademark of Microsoft incorporated in US and other countries.
          </div>
        </div>
        <div className={css.trademarkRowInner2}>
          <br/><br/>
        </div>
      </div>
    </div>

      </div>
   
  );
}; 

export default Footer;

